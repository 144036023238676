import { useStaticQuery, graphql } from 'gatsby'

const useIDXConsultationsMetadata = () => {
  const query = useStaticQuery(graphql`
    query IDXConsultationsMetadata {
      bannerBG: file(relativePath: { eq: "idx-consultations/banner/bg.png" }) {
        id
        ...IDXConsultationsImageOptimize
      }
      bannerLaptop: file(
        relativePath: { eq: "idx-consultations/banner/laptop.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
      bannerTablet1: file(
        relativePath: { eq: "idx-consultations/banner/tablet1.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
      bannerTablet2: file(
        relativePath: { eq: "idx-consultations/banner/tablet2.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }

      contactBG: file(
        relativePath: { eq: "idx-consultations/contact/bg.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
      contactImg: file(
        relativePath: { eq: "idx-consultations/contact/img.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
      contactImgDesktop: file(
        relativePath: { eq: "idx-consultations/contact/img-desktop.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }

      closingLeftBG: file(
        relativePath: { eq: "idx-consultations/closing/left-bg.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
      closingRightBG: file(
        relativePath: { eq: "idx-consultations/closing/right-bg.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
      closingLeftTablet: file(
        relativePath: { eq: "idx-consultations/closing/left-tablet.png" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
      closingRightTablet: file(
        relativePath: { eq: "idx-consultations/closing/right-tablet.png" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
      closingLeftScreenshot: file(
        relativePath: { eq: "idx-consultations/closing/left-screenshot.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
      closingRightScreenshot: file(
        relativePath: { eq: "idx-consultations/closing/right-screenshot.jpg" }
      ) {
        id
        ...IDXConsultationsImageOptimize
      }
    }

    fragment IDXConsultationsImageOptimize on File {
      childImageSharp {
        gatsbyImageData(placeholder: NONE, quality: 90, formats: [AUTO])
      }
    }
  `)

  return query
}

export default useIDXConsultationsMetadata
