// extracted by mini-css-extract-plugin
export var bg = "Banner-module--bg--8e655";
export var bottomPart = "Banner-module--bottom-part--f5012";
export var breadcrumbs = "Banner-module--breadcrumbs--6b31b";
export var container = "Banner-module--container--d4633";
export var devices = "Banner-module--devices--60df9";
export var form = "Banner-module--form--5fb22";
export var heading = "Banner-module--heading--69cb4";
export var laptop = "Banner-module--laptop--4a741";
export var tablet1 = "Banner-module--tablet1--e9f7d";
export var tablet2 = "Banner-module--tablet2--27661";
export var upperPart = "Banner-module--upper-part--f04a0";
export var wrapper = "Banner-module--wrapper--d656c";