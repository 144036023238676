import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { useIntersectOnMobile } from '@hooks/useIntersect'
import * as cx from './Contact.module.scss'

const Contact = ({
  wrapperClassName,
  bg,
  img,
  heading,
  subheading,
  paragraph,
  headingClassName = 'heading-5',
  subheadingClassName = 'subtitle-1',
  paragraphClassName = 'subtitle-5',
}) => {
  const [
    detailsRef,
    isDetailsIntersecting,
    isDetailsMobile,
  ] = useIntersectOnMobile(
    {
      root: null,
      threshold: 1,
    },
    767
  )

  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <div className={cx.imgContainer}>
        <ElementImage src={img} alt={`${heading} consultation`} />
      </div>
      <div
        className={cx.detailsContainer}
        style={{ backgroundImage: `url(${bg})` }}
      >
        <div
          ref={detailsRef}
          className={clsx([cx.details], {
            [cx.detailsIntersect]: isDetailsMobile && isDetailsIntersecting,
          })}
        >
          <h3>
            <span
              className={clsx(subheadingClassName, {
                'tempo-hidden': isDetailsMobile && !isDetailsIntersecting,
              })}
            >
              {subheading}
            </span>
            <span
              className={clsx(headingClassName, {
                'tempo-hidden': isDetailsMobile && !isDetailsIntersecting,
              })}
            >
              {heading}
            </span>
          </h3>
          <p className={paragraphClassName}>{paragraph}</p>
        </div>
      </div>
    </section>
  )
}

Contact.propTypes = {
  wrapperClassName: PropTypes.string,
  bg: PropTypes.string,
  img: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  paragraph: PropTypes.string,
  headingClassName: PropTypes.string,
  subheadingClassName: PropTypes.string,
  paragraphClassName: PropTypes.string,
}

export default Contact
