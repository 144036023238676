import React from 'react'

export const pageData = {
  title: 'IDX Consultations',
  breadcrumbs: [
    { text: 'Home', url: '/' },
    { text: 'Resources', url: '/resources/' },
    {
      text: 'IDX Solutions',
      url: '/resources/idx-solutions/',
    },
    {
      text: 'IDX Consultations',
      url: '/resources/idx-solutions/idx-consultations/',
    },
  ],
  uri: '/resources/idx-solutions/idx-consultations/',
}

export const bannerData = {
  heading: {
    text: 'IDX Consultation',
    className: 'heading-1',
    tabletClassName: 'heading-3',
    phoneClassName: 'heading-2',
  },
  subheading1: {
    text: 'Learn more how you can benefit from IDX!',
    className: 'subtitle-3',
    tabletClassName: 'subtitle-6',
  },
  subheading2: {
    text: 'Complete the form below for an evaluation of your IDX needs.',
    className: 'subtitle-5',
  },
  altImg: {
    laptop: 'Sharlene Chang website',
    tablet1: 'Metropolitan website',
    tablet2: 'Map coverage',
  },
  form: {
    heading: 'Request a Free IDX Consultation',
    buttonText: 'Submit Form',
  },
}

export const contactData = {
  heading: {
    text: '800.979.5799',
    className: 'heading-5',
    tabletClassName: 'heading-2',
  },
  subheading: {
    text: 'or call',
    className: 'subtitle-1',
    phoneClassName: 'subtitle-7',
  },
  paragraph: {
    text: 'For a Free IDX Consultation with our Web Marketing Strategists',
    className: 'subtitle-5',
  },
}

export const closingData = {
  leftHeading: {
    text: 'Not sure<br/> which package<br/> to get?',
    className: 'subtitle-2',
  },
  leftSubheading: {
    text: 'Review our IDX Packages',
    className: 'subtitle-5',
  },
  leftCta: 'VIEW IDX PACKAGES',
  rightHeading: {
    text: '20 Best IDX<br/> Real Estate Websites',
    className: 'heading-3',
  },
  rightCta: 'VIEW SHOWCASE',
  leftTabletAlt: 'Caroll Group screenshot',
  rightTabletAlt: 'Ivan Estrada screenshot',
  leftScreenshotAlt: 'Oppenheim Group screenshot',
  rightScreenshotAlt: 'Altman Brothers screenshot',
}
