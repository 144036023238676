import React from 'react'
import PropTypes from 'prop-types'
import parse from 'html-react-parser'
import clsx from 'clsx'

import ElementImage from '@components/global/element/Image'
import { Button } from '@components/global/buttons/Buttons'
import * as cx from './Closing.module.scss'

const Closing = ({
  wrapperClassName,
  leftHeading,
  leftSubheading,
  rightHeading,
  leftCta,
  rightCta,
  leftBG,
  rightBG,
  leftTablet,
  rightTablet,
  leftScreenshot,
  rightScreenshot,
  leftTabletAlt,
  rightTabletAlt,
  leftScreenshotAlt,
  rightScreenshotAlt,
  leftHeadingClassName = 'subtitle-2',
  leftSubheadingClassName = 'subtitle-5',
  rightHeadingClassName = 'heading-3',
}) => {
  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <div className={cx.container}>
        <div className={cx.leftPart}>
          <div className={cx.detailsContainer}>
            <h5>
              <span className={leftHeadingClassName}>{parse(leftHeading)}</span>
              <span className={leftSubheadingClassName}>{leftSubheading}</span>
            </h5>
            <Button large text={leftCta} />
          </div>
          <div
            className={cx.leftImgContainer}
            style={{ backgroundImage: `url(${leftBG})` }}
          >
            <ElementImage src={leftTablet} alt={leftTabletAlt} />
          </div>
        </div>

        <div
          className={cx.rightPart}
          style={{ backgroundImage: `url(${rightBG})` }}
        >
          <h4 className={rightHeadingClassName}>{parse(rightHeading)}</h4>
          <Button large text={rightCta} />
          <div className={cx.rightImgContainer}>
            <ElementImage src={leftScreenshot} alt={leftScreenshotAlt} />
            <ElementImage src={rightTablet} alt={rightTabletAlt} />
            <ElementImage src={rightScreenshot} alt={rightScreenshotAlt} />
          </div>
        </div>
      </div>
    </section>
  )
}

Closing.propTypes = {
  wrapperClassName: PropTypes.string,
  leftHeading: PropTypes.string,
  leftSubheading: PropTypes.string,
  rightHeading: PropTypes.string,
  leftCta: PropTypes.string,
  rightCta: PropTypes.string,
  leftBG: PropTypes.string,
  rightBG: PropTypes.string,
  leftTablet: PropTypes.string,
  rightTablet: PropTypes.string,
  leftScreenshot: PropTypes.string,
  rightScreenshot: PropTypes.string,
  leftTabletAlt: PropTypes.string,
  rightTabletAlt: PropTypes.string,
  leftScreenshotAlt: PropTypes.string,
  rightScreenshotAlt: PropTypes.string,
  leftHeadingClassName: PropTypes.string,
  leftSubheadingClassName: PropTypes.string,
  rightHeadingClassName: PropTypes.string,
}

export default Closing
