import React from 'react'

import LayoutInnerPage from '@src/layouts/InnerPage'
import Seo from '@components/seo/Index'
import { Banner, Contact, Closing } from '@components/pages/idx-consultations'

import {
  pageData as page,
  bannerData,
  contactData,
  closingData,
} from '@src/data/IDXConsultations'
import useIDXConsultationsMetadata from '@hooks/idx-consultations-metadata'
import useMedia, { media } from '@hooks/useMedia'
import useWindowSize from '@hooks/useWindowSize'
import { extractGatsbyImageDataRelativePath as extractImage } from '@utils/imageFile'
import * as cx from './IDXConsultations.module.scss'

const IDXConsultations = () => {
  const {
    bannerBG,
    bannerLaptop,
    bannerTablet1,
    bannerTablet2,
    contactImg,
    contactImgDesktop,
    contactBG,
    closingLeftBG,
    closingRightBG,
    closingLeftTablet,
    closingRightTablet,
    closingLeftScreenshot,
    closingRightScreenshot,
  } = useIDXConsultationsMetadata()
  const isTablet = useMedia(media.tablet)
  const isPhone = useMedia(media.phone)
  const isDesktop = useWindowSize().width >= 1024

  return (
    <LayoutInnerPage hasWhiteTransparentNav wrapperClassName={cx.wrapper}>
      <Seo title={page.title} uri={page.uri} />
      <Banner
        breadcrumbs={page.breadcrumbs}
        bg={extractImage(bannerBG)}
        laptopImg={extractImage(bannerLaptop)}
        tablet1Img={extractImage(bannerTablet1)}
        tablet2Img={extractImage(bannerTablet2)}
        heading={bannerData.heading.text}
        subheading1={bannerData.subheading1.text}
        subheading2={bannerData.subheading2.text}
        headingClassName={
          isPhone
            ? bannerData.heading.phoneClassName
            : isTablet
            ? bannerData.heading.tabletClassName
            : bannerData.heading.className
        }
        subheading1ClassName={
          isTablet
            ? bannerData.subheading1.tabletClassName
            : bannerData.subheading1.className
        }
        altLaptop={bannerData.altImg.laptop}
        altTablet1={bannerData.altImg.Tablet1}
        altTablet2={bannerData.altImg.Tablet2}
        formHeading={bannerData.form.heading}
        formButtonText={bannerData.form.buttonText}
      />
      <Contact
        heading={contactData.heading.text}
        subheading={contactData.subheading.text}
        paragraph={contactData.paragraph.text}
        img={extractImage(isDesktop ? contactImgDesktop : contactImg)}
        bg={extractImage(contactBG)}
        headingClassName={
          isTablet
            ? contactData.heading.tabletClassName
            : contactData.heading.className
        }
        subheadingClassName={
          isPhone
            ? contactData.subheading.phoneClassName
            : contactData.subheading.className
        }
      />
      <Closing
        leftHeading={closingData.leftHeading.text}
        leftSubheading={closingData.leftSubheading.text}
        rightHeading={closingData.rightHeading.text}
        leftCta={closingData.leftCta}
        rightCta={closingData.rightCta}
        leftBG={extractImage(closingLeftBG)}
        rightBG={extractImage(closingRightBG)}
        leftTablet={extractImage(closingLeftTablet)}
        rightTablet={extractImage(closingRightTablet)}
        leftScreenshot={extractImage(closingLeftScreenshot)}
        rightScreenshot={extractImage(closingRightScreenshot)}
        leftTabletAlt={closingData.leftTabletAlt}
        rightTabletAlt={closingData.rightTabletAlt}
        leftScreenshotAlt={closingData.leftScreenshotAlt}
        rightScreenshotAlt={closingData.rightScreenshotAlt}
      />
    </LayoutInnerPage>
  )
}

export default IDXConsultations
