import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'

import Breadcrumbs from '@components/seo/Breadcrumbs'
import ConsultationForm from '@components/global/forms/ConsultationForm'
import ElementImage from '@components/global/element/Image'
import DeviceTablet from '@components/global/devices/Tablet'
import DeviceLaptop from '@components/global/devices/Laptop'
import LaptopPlaceholder from '/content/assets/idx-consultations/banner/laptop-placeholder.png'
import TabletPlaceholder from '/content/assets/idx-consultations/banner/tablet-placeholder.png'
import * as cx from './Banner.module.scss'

const Banner = ({
  wrapperClassName,
  breadcrumbs,
  bg,
  heading,
  subheading1,
  subheading2,
  laptopImg,
  tablet1Img,
  tablet2Img,
  laptopAlt,
  tablet1Alt,
  tablet2Alt,
  formHeading,
  formButtonText,
  headingClassName = 'heading-1',
  subheading1ClassName = 'subtitle-3',
  subheading2ClassName = 'subtitle-5',
}) => {
  return (
    <section className={clsx(wrapperClassName, [cx.wrapper])}>
      <div className={cx.upperPart}>
        <ElementImage className={cx.bg} src={bg} alt="bg" lazy={false} />
        <Breadcrumbs
          wrapperClassName={`${cx.breadcrumbs} global-breadcrumbs`}
          data={breadcrumbs}
        />
        <div className={cx.container}>
          <h2 className={cx.heading}>
            <span className={headingClassName}>{heading}</span>
            <span className={subheading1ClassName}>{subheading1}</span>
            <span className={subheading2ClassName}>{subheading2}</span>
          </h2>
          <div className={cx.devices}>
            <DeviceLaptop
              className={cx.laptop}
              imagePlaceholder={LaptopPlaceholder}
              imageSrc={laptopImg}
              imageFileAlt={laptopAlt}
              lazy={false}
              width={900}
              height={506}
            />
            <DeviceTablet
              className={cx.tablet1}
              imagePlaceholder={TabletPlaceholder}
              imageSrc={tablet1Img}
              imageFileAlt={tablet1Alt}
              lazy={false}
              width={600}
              height={460}
            />
            <DeviceTablet
              className={cx.tablet2}
              imagePlaceholder={TabletPlaceholder}
              imageSrc={tablet2Img}
              imageFileAlt={tablet2Alt}
              lazy={false}
              width={600}
              height={460}
            />
          </div>
        </div>
      </div>
      <div className={cx.bottomPart}>
        <ConsultationForm
          hasIdxData
          wrapperClassName={cx.form}
          heading={formHeading}
          buttonText={formButtonText}
        />
      </div>
    </section>
  )
}

Banner.propTypes = {
  wrapperClassName: PropTypes.string,
  breadcrumbs: PropTypes.array,
  bg: PropTypes.string,
  heading: PropTypes.string,
  subheading1: PropTypes.string,
  subheading2: PropTypes.string,
  laptopImg: PropTypes.string,
  tablet1Img: PropTypes.string,
  tablet2Img: PropTypes.string,
  laptopAlt: PropTypes.string,
  tablet1Alt: PropTypes.string,
  tablet2Alt: PropTypes.string,
  formHeading: PropTypes.string,
  formButtonText: PropTypes.string,
  headingClassName: PropTypes.string,
  subheading1ClassName: PropTypes.string,
  subheading2ClassName: PropTypes.string,
}

export default Banner
